var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "vue-good-table",
    {
      attrs: {
        columns: _vm.columns,
        rows: _vm.rows,
        theme: _vm.theme,
        totalRows: _vm.totalRows,
        "pagination-options": _vm.options,
        "select-options": _vm.selectOptions,
      },
      on: {
        "on-selected-rows-change": _vm.onSelectedRowsChange,
        "on-page-change": _vm.onPageChange,
        "on-per-page-change": _vm.onPerPageChange,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "table-row",
            fn: function (props) {
              return [
                _vm._t("table-row", null, {
                  data: props,
                }),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "template",
        {
          slot: "selected-row-actions",
        },
        [_vm._t("selected-row-actions")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }