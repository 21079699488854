var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "confirmModal",
        title: _vm.operationStatus
          ? _vm.$t("agreeList")
          : _vm.$t("disagreeList"),
        "header-bg-variant": "primary",
        "ok-title": _vm.$t("confirm"),
        "cancle-title": _vm.$t("cancle"),
        centered: "",
        "button-size": "sm",
      },
      on: {
        ok: function ($event) {
          return _vm.modalClickOk()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
        },
        [
          _c("h5", {
            staticClass: "mb-4",
            domProps: {
              innerHTML: _vm._s(
                _vm.operationStatus
                  ? _vm.$t("agreeListConfirmQuestion")
                  : _vm.$t("disagreeListConfirmQuestion")
              ),
            },
          }),
          _c(
            "div",
            {
              staticClass: "confirm-modal-content",
              class: {
                "text-left": _vm.selectedRow.length > 1,
              },
            },
            _vm._l(_vm.selectedRow, function (row) {
              return _c(
                "p",
                {
                  key: row.id,
                },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: ["far", "calendar"],
                    },
                  }),
                  _c("span", [_vm._v("：" + _vm._s(row.class_time))]),
                  _c("font-awesome-icon", {
                    staticClass: "ml-2",
                    attrs: {
                      icon: "user",
                    },
                  }),
                  _c("span", [_vm._v("：" + _vm._s(row.vip.english_name))]),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
      _c("hr"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }